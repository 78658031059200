<template>
    <div class="Release">
        <div class="hdcon">
            <div class="hdd">
                <Icon name="arrow-left" @click="$router.back(-1)" />
                <div class="tit">专业级评论</div>
                <div class="btn">
                    <button>存草稿</button>
                    <button class="fa">发布</button>
                </div>
            </div>
        </div>
        <div class="hd">
            <div class="desc">（一下评论，根据我们的审核情况评分获得50-100积分，并且获得精华）</div>
        </div>
        <div class="title">品鉴数据</div>
        <div class="data">
            <div class="item">
                <div class="label">颜色：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="8">淡</Col>
                        <Col span="8" class="bg-color">中</Col>
                        <Col span="8">深</Col>
                    </Row>
                </div>
            </div>
            <div class="item">
                <div class="label">酒精度：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="8">低</Col>
                        <Col span="8" class="bg-color">中</Col>
                        <Col span="8">高</Col>
                    </Row>
                </div>
            </div>
            <div class="item">
                <div class="label">香气：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="5">淡</Col>
                        <Col span="5">中-</Col>
                        <Col span="5">中</Col>
                        <Col span="5" class="bg-color">中+</Col>
                        <Col span="4">浓</Col>
                    </Row>
                </div>
            </div>
            <div class="item">
                <div class="label">酒体：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="5">低</Col>
                        <Col span="5">中-</Col>
                        <Col span="5">中</Col>
                        <Col span="5" class="bg-color">中+</Col>
                        <Col span="4">饱满</Col>
                    </Row>
                </div>
            </div>
            <div class="item">
                <div class="label">酸度：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="5">低</Col>
                        <Col span="5">中-</Col>
                        <Col span="5">中</Col>
                        <Col span="5" class="bg-color">中+</Col>
                        <Col span="4">高</Col>
                    </Row>
                </div>
            </div>
            <div class="item">
                <div class="label">余味：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="5">低</Col>
                        <Col span="5">中-</Col>
                        <Col span="5">中</Col>
                        <Col span="5" class="bg-color">中+</Col>
                        <Col span="4">长</Col>
                    </Row>
                </div>
            </div>
            <div class="item">
                <div class="label">单宁：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="5">低</Col>
                        <Col span="5">中-</Col>
                        <Col span="5">中</Col>
                        <Col span="5">中+</Col>
                        <Col span="4" class="bg-color">高</Col>
                    </Row>
                </div>
            </div>
            <div class="item">
                <div class="label">品质：</div>
                <div class="selectlabel">
                    <Row>
                        <Col span="5">差</Col>
                        <Col span="5">中</Col>
                        <Col span="5">好</Col>
                        <Col span="5">很好</Col>
                        <Col span="4" class="bg-color">特好</Col>
                    </Row>
                </div>
            </div>
        </div>
        <div class="title">颜色</div>
        <div class="concon">
            <div class="coloritem">
                <div class="label">白葡萄酒：</div>
                <div class="colorselect">
                    <RadioGroup v-model="radio1" direction="horizontal" checked-color="#e93323">
                        <Radio name="1">青柠色</Radio>
                        <Radio name="2">柠檬色</Radio>
                        <Radio name="3">金色</Radio>
                        <Radio name="4">琥珀色</Radio>
                        <Radio name="5">棕色</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div class="coloritem">
                <div class="label">红葡萄酒：</div>
                <div class="colorselect">
                    <RadioGroup v-model="radio2" direction="horizontal" checked-color="#e93323">
                        <Radio name="1">紫色</Radio>
                        <Radio name="2">宝石色</Radio>
                        <Radio name="3">石榴红</Radio>
                        <Radio name="4">红茶色</Radio>
                        <Radio name="5">棕色</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div class="coloritem">
                <div class="label">桃红葡萄酒：</div>
                <div class="colorselect">
                    <RadioGroup v-model="radio3" direction="horizontal" checked-color="#e93323">
                        <Radio name="1">粉色</Radio>
                        <Radio name="2">黄红色</Radio>
                        <Radio name="3">橙色</Radio>
                    </RadioGroup>
                </div>
            </div>
        </div>
        <div class="title">嗅觉-香气特征</div>
        <div class="concon">
            <div>
                <Field
                v-model="message"
                rows="1"
                autosize
                label="一类香气："
                type="textarea"
                maxlength="120"
                placeholder="请输入一类香气"
                show-word-limit
                />
            </div>
            <div>
                <Field
                v-model="message"
                rows="1"
                autosize
                label="二类香气："
                type="textarea"
                maxlength="120"
                placeholder="请输入二类香气"
                show-word-limit
                />
            </div>
            <div>
                <Field
                v-model="message"
                rows="1"
                autosize
                label="三类香气："
                type="textarea"
                maxlength="120"
                placeholder="请输入三类香气"
                show-word-limit
                />
            </div>
        </div>
        <div class="title">味觉-味道特征</div>
        <div class="concon">
            <div>
                <Field
                v-model="message"
                rows="1"
                autosize
                label="一类味道："
                type="textarea"
                maxlength="120"
                placeholder="请输入一类味道"
                show-word-limit
                />
            </div>
            <div>
                <Field
                v-model="message"
                rows="1"
                autosize
                label="二类味道："
                type="textarea"
                maxlength="120"
                placeholder="请输入二类味道"
                show-word-limit
                />
            </div>
            <div>
                <Field
                v-model="message"
                rows="1"
                autosize
                label="三类味道："
                type="textarea"
                maxlength="120"
                placeholder="请输入三类味道"
                show-word-limit
                />
            </div>
        </div>
    </div>
</template>
<script>
    import { Icon, RadioGroup, Radio, Field, Col, Row } from "vant";
    import { Image as VanImage } from 'vant';
    export default {
        name: "Release",
        data () {
            return {
                radio1: '1',
                radio2: '1',
                radio3: '1',
                message: '',
            }
        },
        components: {
            Icon, RadioGroup, Radio, Field, Col, Row
        },
        created() {
            
        },
        mounted: function() {
            
        },
        methods: {
            
        },
    }
</script>

<style scoped lang="scss">
::v-deep{
    .concon{
        .van-field__label{margin-right: 0.1rem;width: auto;}
        .van-cell__value{padding:0.1rem;border: 1px solid #bebec0;}
    }
}
.Release{
    font-size:0.24rem;padding-top: 1rem;
    .hdcon{
        position: fixed;top: 0;left: 0;right: 0;background: #fff;border-bottom: 1px solid #f5f5f5;
        .hdd{
            text-align: center;overflow:hidden;line-height: 1rem;position: relative;color: #000;font-size: 0.32rem;
            i{position: absolute;top: 0;left: 0;line-height: 1rem;font-size: 0.4rem;padding: 0 0.1rem;}
            .btn{
                position: absolute;top: 0.2rem;right: 0;
                button{color:#999;font-size: 0.24rem;border: 1px solid #eee;display: block;float: left;height: 0.6rem;line-height: 0.6rem;margin-right: 0.1rem;border-radius: 3rem;padding: 0 0.2rem;}
                .fa{color:#333;background: #f9dc54;padding: 0 0.3rem;border: 1px solid #fff;}
            }
        }
    }
    .hd{
        text-align: center;color:#999;padding: 0.1rem;
        .tit{font-size: 0.36rem;margin: 0.1rem 0;letter-spacing:0.05rem;}
        .desc{font-size: 0.28rem;}
    }
    .title{background: #fff;font-size: 0.32rem;text-align: center;color:#333;line-height:1rem;letter-spacing:0.05rem;margin-top:0.2rem;border-bottom:1px solid #eee;}
    .data{
        background:#342c44;color:#999;overflow:hidden;padding:0.2rem;
        .item{
            width:100%;float:left;display: flex;margin-top:0.2rem;line-height: 0.4rem;
            .label{flex:none;}
            .selectlabel{
                flex:1;background:#3f354e;
                .van-col{text-align: center;}
            }
        }
    }
    .concon{
        background: #eff0f2;padding: 0.2rem;
        .van-cell{
            background: none;padding: 0;margin-bottom: 0.2rem;
        }
        .coloritem{
            display: flex;
            .label{flex:none;}
            .colorselect{
                .van-radio{margin-bottom: 0.2rem;}
            }
        }
    }
}
</style>